<template>
  <div class="tab-text">
    <vs-button
      class="mt-2"
      color="primary"
      type="border"
      icon-pack="feather"
      icon="icon-plus"
      @click="handleCreate()"
    >Create</vs-button>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[(mutualAction=='edit'||mutualAction=='create') ? detailShow : '', detailHide]"
      >
        <data-table :baseUrl="baseUrl" @id="setId" @action="setAction" :action="mutualAction"></data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="mutualAction=='edit'||mutualAction=='create'"
          v-bind:class="[mutualAction=='edit'||mutualAction=='create' ? detailShow : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <data-form
              v-if="mutualAction=='edit'||mutualAction=='create'"
              :id="id"
              :baseUrl="baseUrl"
              :title="title"
              @action="setAction"
              @close="handleClose"
            ></data-form>
          </vs-row>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import DataTable from "./DataTable";
import DataForm from "./DataForm";
export default {
  components: {
    DataTable,
    DataForm
  },
  data() {
    return {
      title: "Work Team",
      baseUrl: "/api/v1/master/work-team",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      mutualAction: "",
      id: null
    };
  },
  methods: {
    handleCreate() {
      this.id = null;
      this.mutualAction = "create";
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.mutualAction = "";
    },
    setId(val) {
      this.id = val;
    },
    setAction(val) {
      this.mutualAction = val;
    }
  },
  mounted() {
    // this.getData();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      }
    }
  }
};
</script>

 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  /* min-height: 800px; */
  padding: 5px;
}
</style>