<template>
  <vx-card :title="title">
    <!-- <vs-tabs>
      <vs-tab label="Data"> -->
        <data-component></data-component>
      <!-- </vs-tab>
      <vs-tab label="Tree view ">
        <div class="tab-text"></div>
      </vs-tab>
    </vs-tabs> -->
  </vx-card>
</template>
<script>
import DataComponent from "./Data";
export default {
  components: {
    DataComponent
  },
  data() {
    return {
      title: "Work Team"
    };
  }
};
</script>